/* Font Unica77 */
@font-face {
  font-family: 'Unica77';
  font-weight: 100;
  src: local('Unica77'), url(./fonts/Unica77LL-Thin.otf) format('opentype');
}
@font-face {
  font-family: 'Unica77';
  font-weight: 300;
  src: local('Unica77'), url(./fonts/Unica77LL-Light.otf) format('opentype');
}
@font-face {
  font-family: 'Unica77';
  font-weight: 400;
  src: local('Unica77'), url(./fonts/Unica77LL-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Unica77';
  font-weight: 500;
  src: local('Unica77'), url(./fonts/Unica77LL-Medium.otf) format('opentype');
}

@font-face {
  font-family: 'Unica77';
  font-weight: 700;
  src: local('Unica77'), url(./fonts/Unica77LL-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Unica77';
  font-weight: 900;
  src: local('Unica77'), url(./fonts/Unica77LL-Black.otf) format('opentype');
}

* {
  box-sizing: border-box;
  font-family: 'Unica77';
}

html,
body {
  margin: 0;
  height: 100%;
  font-family: 'Unica77';
  background-color: white;
}
#root {
  height: 100%;
  width: 100%;
}

.MuiBackdrop-root {
  background: rgba(0, 0, 0, 0.85) !important;
}
